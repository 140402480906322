import React, { useState } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Layout from "../components/layout";
import ProjectCard from "../components/project/project-card";
import ProjectUpdates from "../components/project/project-updates";
import ProjectFiles from "../components/project/project-files";
import SwToast from "../components/toast/sw-toast";
import useProject from "../hooks/useProject";

interface Props {
  location: Location;
}

const Project = ({ location }: Props) => {
  const intl = useIntl();

  const searchParams = new URLSearchParams(`${location.search}`);

  const projectId: string = searchParams.has("id")
    ? searchParams.get("id") || ""
    : "";

  const { projectQuery } = useProject({ id: projectId });

  const [showFailToast, setShowFailToast] = useState(false);

  // @ts-ignore
  const [errorMessage, setErrorMessage] = useState();

  if (projectQuery.isPending) {
    return (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );
  }

  if (projectQuery.isError) {
    return (
      <Layout>
        <div>Error</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <SwToast
        show={showFailToast}
        onClose={() => setShowFailToast(false)}
        header={intl.formatMessage({
          id: "project.get.fail",
          defaultMessage: "Get Project failed",
        })}
        body={
          errorMessage
            ? `${(errorMessage as any).name}: ${(errorMessage as any).message}`
            : ""
        }
      ></SwToast>
      <h1 className="block-title">{projectQuery.data?.title}</h1>
      <h2>
        <FormattedMessage
          id="project.summary"
          defaultMessage="Project Summary"
        />
      </h2>
      <Tabs
        defaultActiveKey={
          (location as any).state && (location as any).state.commentId
            ? `updates`
            : `projectCard`
        }
        id="project-update-tabs"
        className="mb-3 bg-secondary"
      >
        <Tab
          eventKey="updates"
          tabClassName="rounded-0"
          title={
            <FormattedMessage id="project.updates" defaultMessage="Updates" />
          }
        >
          {projectQuery.data ? (
            <ProjectUpdates
              project={projectQuery.data}
              commentId={
                (location as any).state
                  ? (location as any).state.commentId
                  : null
              }
            ></ProjectUpdates>
          ) : (
            "Loading"
          )}
        </Tab>
        <Tab
          // disabled={true}
          eventKey="files"
          tabClassName="rounded-0"
          title={<FormattedMessage id="project.files" defaultMessage="Files" />}
        >
          <ProjectFiles files={projectQuery.data?.files} id={projectId} />
        </Tab>
        <Tab
          eventKey="projectCard"
          tabClassName="rounded-0"
          title={
            <FormattedMessage
              id="project.projectCard"
              defaultMessage="Project Card"
            />
          }
        >
          {projectQuery.data ? (
            <ProjectCard project={projectQuery.data} />
          ) : (
            "Loading"
          )}
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default Project;
