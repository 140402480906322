import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import React, { useEffect, useState } from "react";

import {
  addCommentToProject,
  deleteCommentFromProject,
} from "../../services/getRequest";
import { components } from "../../types/projects";
import ConfirmDeleteCommentModal from "../modal/confirm-delete-comment-modal";
import SwToast from "../toast/sw-toast";
import AddCommentForm from "./../add-comment-form";
import CommentCard from "./../comment-card";

interface Props {
  project: components["schemas"]["Project"];
  commentId: string;
}

const ProjectUpdates = (props: Props) => {
  const { project } = props;
  const intl = useIntl();

  const [showModal, setShowModal] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailToast, setShowFailToast] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  const [currentCommentId, setCurrentCommentId] = useState("");

  const handleClose = () => setShowModal(false);
  const handleShow = (commentId: string) => {
    setShowModal(true);
    setCurrentCommentId(commentId);
  };

  const [projectComments, setProjectComments] =
    useState<components["schemas"]["Comment"][]>();

  useEffect(() => {
    if (project.comments) {
      setProjectComments(project.comments);
    }
  }, [project]);

  const handleAddComment = (
    value: string,
    mention: components["schemas"]["Comment"]["taggedUser"]
  ) => {
    if (!!value && project.id) {
      addCommentToProject(project.id, value, mention).then((newComment) => {
        let newComments = projectComments
          ? projectComments.concat(newComment)
          : [newComment];
        setProjectComments(newComments);
      });
    }
  };

  const handleDeleteComment = () => {
    setShowModal(false);
    if (!!currentCommentId && project.id) {
      deleteCommentFromProject(project.id, currentCommentId)
        .then((deletedComment: { deletedId: string }) => {
          let newComments = projectComments?.filter(
            (comment) => comment.id !== deletedComment.deletedId
          );
          setProjectComments(newComments);

          setCurrentCommentId("");
          setShowSuccessToast(true);
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(error);
          setShowFailToast(true);
        });
    }
  };

  return (
    <div>
      <AddCommentForm onSubmit={handleAddComment}></AddCommentForm>

      {projectComments?.length ? (
        <ul className="ms-0 ps-0 list-unstyled">
          {projectComments
            .filter((comment) => comment.timestamp !== undefined)
            .sort((a, b) => b.timestamp - a.timestamp)
            .map((comment: components["schemas"]["Comment"]) => {
              let commentSelected = props.commentId == comment.id;
              return (
                <li key={comment.id} id={comment.id}>
                  <CommentCard
                    comment={comment}
                    isCommentSelected={commentSelected}
                    onDelete={handleShow}
                  />
                </li>
              );
            })}
        </ul>
      ) : (
        <FormattedMessage
          id="project.no_comments"
          defaultMessage="There are no comments"
        />
      )}
      <ConfirmDeleteCommentModal
        show={showModal}
        onHide={handleClose}
        handleClose={handleClose}
        handleDeleteComment={handleDeleteComment}
      ></ConfirmDeleteCommentModal>

      <SwToast
        show={showFailToast}
        onClose={() => setShowFailToast(false)}
        header={intl.formatMessage({
          id: "comment.delete.fail",
          defaultMessage: "Delete Failed",
        })}
        body={
          errorMessage ? `${errorMessage.name}: ${errorMessage.message}` : ""
        }
      ></SwToast>
      <SwToast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        header={intl.formatMessage({
          id: "comment.delete.success",
          defaultMessage: "Comment Deleted",
        })}
        body={intl.formatMessage({
          id: "comment.delete.success.message",
          defaultMessage: "Comment was successfully deleted",
        })}
      ></SwToast>
    </div>
  );
};

export default ProjectUpdates;
