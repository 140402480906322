import React, { useState } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

import { components } from "../../types/projects";
import ProjectPeopleForm from "./project-people-form";
import { updateProject } from "../../services/getRequest";
import ProjectPeopleReadonly from "./project-people-readonly";
import ProjectKeyInfoForm from "./project-key-info-form";
import ProjectKeyInfoReadonly from "./project-key-info-readonly";
import ProjectDescriptionForm from "./project-description-form";
import ProjectDescriptionReadonly from "./project-description-readonly";
import ProjectDatesForm from "./project-dates-form";
import ProjectDatesReadonly from "./project-dates-readonly";

interface Props {
  project: components["schemas"]["Project"];
}

const ProjectCard = (props: Props) => {
  const { project } = props;
  const intl = useIntl();

  const [updatedProject, setProject] = useState(project);
  const [showPeopleEdit, setShowPeopleEdit] = useState(false);
  const [showKeyInfoEdit, setShowKeyInfoEdit] = useState(false);
  const [showDescriptionEdit, setShowDescriptionEdit] = useState(false);
  const [showDatesEdit, setShowDatesEdit] = useState(false);

  const handleShowKeyInfoEdit = () => setShowKeyInfoEdit(true);
  const handleHideKeyInfoEdit = () => setShowKeyInfoEdit(false);

  const handleShowPeopleEdit = () => setShowPeopleEdit(true);
  const handleHidePeopleEdit = () => setShowPeopleEdit(false);

  const handleShowDescriptionEdit = () => setShowDescriptionEdit(true);
  const handleHideDescriptionEdit = () => setShowDescriptionEdit(false);

  const handleShowDatesEdit = () => setShowDatesEdit(true);
  const handleHideDatesEdit = () => setShowDatesEdit(false);

  const handleUpdateProject = (values: components["schemas"]["Project"]) => {
    if (project.id) {
      updateProject(project.id, values)
        .then((updatedProject) => {
          setProject(updatedProject);
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <div className="container">
      <div className="row">
        <section className="col-12 col-lg-4">
          {showKeyInfoEdit ? (
            <ProjectKeyInfoForm
              project={updatedProject}
              onSubmit={handleUpdateProject}
              onCancel={handleHideKeyInfoEdit}
            ></ProjectKeyInfoForm>
          ) : (
            <ProjectKeyInfoReadonly
              project={updatedProject}
              onClickEdit={handleShowKeyInfoEdit}
            ></ProjectKeyInfoReadonly>
          )}
        </section>
        {/* "WIPS" | "WIPS & Sport Wales" | "Sport Wales" */}
        <section className="col-12 col-lg-5">
          {showPeopleEdit ? (
            <ProjectPeopleForm
              lead={updatedProject.lead}
              practitioner={updatedProject.practitioner}
              sportLead={updatedProject.sportLead}
              partnerLiaison={updatedProject.partnerLiaison}
              project={project}
              hasSportLead={true}
              hasPartnerLiaison={project.prioritisation !== "Athlete"}
              options={{
                lead:
                  project.projectType === "WIPS"
                    ? intl.formatMessage({
                        id: "WIPS.practitioner",
                        defaultMessage: "WIPS Practitioner",
                      })
                    : intl.formatMessage({
                        id: "projectLead",
                        defaultMessage: "Project Lead",
                      }),
                practitioner:
                  project.projectType === "WIPS"
                    ? undefined
                    : intl.formatMessage({
                        id: "sportwales.practitioners",
                        defaultMessage: "Sport Wales Practitioner(s)",
                      }),
                sportLead: intl.formatMessage({
                  id: "sport_lead",
                  defaultMessage: "Sport Lead",
                }),
                partnerLiaison: intl.formatMessage({
                  id: "partner_liaison",
                  defaultMessage: "Partner Liaison",
                }),
              }}
              helpText={{
                partnerLiaison: intl.formatMessage({
                  id: "partner_liaison.helptext",
                  defaultMessage:
                    "Partner Liaison (Sport Wales partner-facing staff e.g. Relationship Managers, Performance Advisors, Coaching Officers, etc)",
                }),
              }}
              onSubmit={handleUpdateProject}
              onCancel={handleHidePeopleEdit}
            ></ProjectPeopleForm>
          ) : (
            <ProjectPeopleReadonly
              key={updatedProject.id}
              project={updatedProject}
              onClickEdit={handleShowPeopleEdit}
              options={{
                lead:
                  project.projectType === "WIPS"
                    ? intl.formatMessage({
                        id: "WIPS.practitioner",
                        defaultMessage: "WIPS Practitioner",
                      })
                    : intl.formatMessage({
                        id: "projectLead",
                        defaultMessage: "Project Lead",
                      }),
                practitioner:
                  project.projectType === "WIPS"
                    ? undefined
                    : intl.formatMessage({
                        id: "sportwales.practitioners",
                        defaultMessage: "Sport Wales Practitioner(s)",
                      }),
                sportLead: intl.formatMessage({
                  id: "sport_lead",
                  defaultMessage: "Sport Lead",
                }),
                partnerLiaison:
                  project.prioritisation !== "Athlete"
                    ? intl.formatMessage({
                        id: "partner_liaison",
                        defaultMessage: "Partner Liaison",
                      })
                    : undefined,
              }}
              helpText={{
                partnerLiaison: intl.formatMessage({
                  id: "partner_liaison.helptext",
                  defaultMessage:
                    "Partner Liaison (Sport Wales partner-facing staff e.g. Relationship Managers, Performance Advisors, Coaching Officers, etc)",
                }),
              }}
            ></ProjectPeopleReadonly>
          )}
        </section>
        <section className="col-12 col-lg-3">
          {showDatesEdit ? (
            <ProjectDatesForm
              project={updatedProject}
              onSubmit={handleUpdateProject}
              onCancel={handleHideDatesEdit}
            ></ProjectDatesForm>
          ) : (
            <ProjectDatesReadonly
              project={updatedProject}
              onClickEdit={handleShowDatesEdit}
            ></ProjectDatesReadonly>
          )}
        </section>
      </div>
      <section className="row mt-3">
        <div className="col">
          {showDescriptionEdit ? (
            <ProjectDescriptionForm
              project={updatedProject}
              onSubmit={handleUpdateProject}
              onCancel={handleHideDescriptionEdit}
            ></ProjectDescriptionForm>
          ) : (
            <ProjectDescriptionReadonly
              project={updatedProject}
              onClickEdit={handleShowDescriptionEdit}
            ></ProjectDescriptionReadonly>
          )}
        </div>
      </section>
      <section className="row mt-3">
        <div className="col">
          <div className="card">
            <div className="card-header">
              {" "}
              <FormattedMessage
                id="project.submission"
                defaultMessage="Submission"
              />
            </div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">
                      {" "}
                      <FormattedMessage
                        id="project.submitted"
                        defaultMessage="Submitted"
                      />
                    </th>
                    <td>
                      {project.timestamp &&
                        new Date(project.timestamp).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {" "}
                      <FormattedMessage
                        id="project.submittedBy"
                        defaultMessage="Submitted By"
                      />
                    </th>
                    <td>{project.submittedBy?.name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectCard;
