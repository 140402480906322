export enum Discipline {
  ClinicalPsychology = "Clinical Psychology",
  Medical = "Medical",
  PerformanceAdviser = "Performance Adviser",
  PerformanceAnalysis = "Performance Analysis",
  PerformanceLifestyle = "Performance Lifestyle",
  PerformanceNutrition = "Performance Nutrition",
  Physiology = "Physiology",
  Physiotherapy = "Physiotherapy",
  Psychology = "Psychology",
  SoftTissue = "Soft Tissue",
  StrengthConditioning = "Strength & Conditioning",
}
