import React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { ErrorMessage, Formik } from "formik";

import { components } from "../../types/projects";
import { Form } from "react-bootstrap";

interface Props {
  project: components["schemas"]["Project"];
  onSubmit: (value: components["schemas"]["Project"]) => void;
  onCancel: (value: boolean) => void;
}

const ProjectKeyInfoForm = (props: Props) => {
  const { project, onSubmit, onCancel } = props;
  const intl = useIntl();

  const handleCancel = () => {
    onCancel(true);
  };

  // A custom validation function. This must return an object
  // which keys are symmetrical to our values/initialValues
  const validate = (values: components["schemas"]["Project"]) => {
    const errors: {
      title?: string;
      performanceChallenge?: string;
      insight?: string;
      desiredOutcome?: string;
      sustainableContribution?: string;
    } = {};
    if (!values.title) {
      errors.title = intl.formatMessage({
        id: "errors.title_required",
        defaultMessage: "Title is required",
      });
    }
    if (!values.performanceChallenge) {
      errors.performanceChallenge = intl.formatMessage({
        id: "errors.performance_challenge_required",
        defaultMessage: "Performance Question is required",
      });
    }

    if (!values.insight) {
      errors.insight = intl.formatMessage({
        id: "errors.insight_required",
        defaultMessage: "Insight is required",
      });
    }

    if (!values.desiredOutcome) {
      errors.desiredOutcome = intl.formatMessage({
        id: "errors.desired_outcome_required",
        defaultMessage: "Desired Outcome is required",
      });
    }

    if (!values.sustainableContribution) {
      errors.sustainableContribution = intl.formatMessage({
        id: "errors.sustainable_contribution",
        defaultMessage: "Sustainable Contribution is required",
      });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        title: project.title,
        performanceChallenge: project.performanceChallenge,
        insight: project.insight,
        desiredOutcome: project.desiredOutcome,
        sustainableContribution: project.sustainableContribution,
      }}
      validate={validate}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-header">
              {" "}
              <FormattedMessage
                id="project.description"
                defaultMessage="Project Description"
              />
            </div>
            <div className="card-body">
            <div className="mb-3 row">
                <label htmlFor="title" className="col-sm-3 form-label">
                  {" "}
                  <FormattedMessage
                    id="project.title"
                    defaultMessage="Title"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Control
                    name="title"
                    as="textarea"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                  />
                  <ErrorMessage
                    name="title"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="performanceChallenge"
                  className="col-sm-3 form-label"
                >
                  {" "}
                  <FormattedMessage
                    id="project.performanceChallenge"
                    defaultMessage="Performance Question"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Control
                    name="performanceChallenge"
                    as="textarea"
                    onChange={formik.handleChange}
                    value={formik.values.performanceChallenge}
                  />
                  <ErrorMessage
                    name="performanceChallenge"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="insight" className="col-sm-3 form-label">
                  {" "}
                  <FormattedMessage
                    id="project.insight"
                    defaultMessage="Insight"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Control
                    name="insight"
                    as="textarea"
                    onChange={formik.handleChange}
                    value={formik.values.insight}
                  />
                  <ErrorMessage
                    name="insight"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="desiredOutcome" className="col-sm-3 form-label">
                  {" "}
                  <FormattedMessage
                    id="project.desiredOutcome"
                    defaultMessage="Desired Outcome"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Control
                    name="desiredOutcome"
                    as="textarea"
                    onChange={formik.handleChange}
                    value={formik.values.desiredOutcome}
                  />
                  <ErrorMessage
                    name="desiredOutcome"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="sustainableContribution"
                  className="col-sm-3 form-label"
                >
                  {" "}
                  <FormattedMessage
                    id="project.sustainableContribution"
                    defaultMessage="Sustainable Contribution"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Control
                    name="sustainableContribution"
                    as="textarea"
                    onChange={formik.handleChange}
                    value={formik.values.sustainableContribution}
                  />
                  <ErrorMessage
                    name="sustainableContribution"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>
            </div>
            <div className="card-footer d-flex">
              <button
                type="button"
                className="btn btn-secondary-outline"
                onClick={handleCancel}
              >
                <FormattedMessage id="project.cancel" defaultMessage="Cancel" />
              </button>
              <button
                type="submit"
                className="btn btn-secondary ms-auto"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage
                  id="form.save_changes"
                  defaultMessage="Save Changes"
                />
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ProjectKeyInfoForm;
