import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { ErrorMessage, Formik } from "formik";
import { Form } from "react-bootstrap";
import Select from "react-select";

import { components } from "../../types/projects";
import { Status } from "../../types/form/project-status.enum";
import { Discipline } from "../../types/form/disciplines.enum";
import { sports } from "../../types/form/sports.const";
import { Prioritisation } from "../../types/form/prioritisation.enum";
import { ProjectType } from "../../types/form/project-type.enum";
import sportsOptions from "../../types/form/sports-options.const";

const authCheck = `/.auth/me`;
const userURL = `/api/user`;

interface Props {
  project: components["schemas"]["Project"];
  onSubmit: (value: components["schemas"]["Project"]) => void;
  onCancel: (value: boolean) => void;
}

const ProjectKeyInfoForm = (props: Props) => {
  const { project, onSubmit, onCancel } = props;
  const [loggedIn, setLoggedIn] = useState(false);
  const [practitioner, setPractitioner] = useState(false);
  const [WIPSUser, setWIPSUser] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const authResponse = await fetch(authCheck);
      const authJson = await authResponse.json();
      if (authJson.clientPrincipal) {
        const res = await fetch(userURL);
        const json = await res.json();
        setLoggedIn(json.roles.includes("authorised"));
        setPractitioner(json.roles.includes("practitioner"));
        setWIPSUser(json.roles.includes("WIPS"));
      }
    };
    fetchData();
  }, [loggedIn]);
  const intl = useIntl();

  const handleCancel = () => {
    onCancel(true);
  };

  // Filter out any sports that aren't in SW predefined list of available sports
  const cleanSports = project.sport
    ?.filter((singleSport) => {
      return sports.includes(singleSport);
    })
    .map((sport) => {
      return { value: sport as string, label: sport as string };
    });

  // A custom validation function. This must return an object
  // which keys are symmetrical to our values/initialValues
  const validate = (values: components["schemas"]["Project"]) => {
    const errors: {
      status?: string;
      sport?: string;
      disciplines?: string;
      prioritisation?: string;
      ranking?: number;
    } = {};
    if (!values.lead) {
      errors.status = intl.formatMessage({
        id: "errors.status_required",
        defaultMessage: "Status is required",
      });
    }

    if (!values.sport) {
      errors.sport = intl.formatMessage({
        id: "errors.sport_required",
        defaultMessage: "Sport is required",
      });
    }

    if (!values.disciplines) {
      errors.disciplines = intl.formatMessage({
        id: "errors.disciplines_required",
        defaultMessage: "Disciplines are required",
      });
    }

    if (!values.prioritisation) {
      errors.prioritisation = intl.formatMessage({
        id: "errors.prioritisation_required",
        defaultMessage: "Prioritisation is required",
      });
    }
    if (!values.projectType) {
      errors.prioritisation = intl.formatMessage({
        id: "errors.projectType_required",
        defaultMessage: "Project Type is required",
      });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        status: project.status,
        sport: project.sport,
        disciplines: project.disciplines,
        prioritisation: project.prioritisation,
        projectType: project.projectType,
      }}
      validate={validate}
      onSubmit={(values) => {
        if (values.disciplines === null) {
          delete values.disciplines;
        }
        onSubmit(values);
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-header">
              {" "}
              <FormattedMessage
                id="project_overview.key_info"
                defaultMessage="Project Key Info"
              />
            </div>
            <div className="card-body">
              <div className="mb-3 row">
                <label htmlFor="status" className="col-sm-3 form-label">
                  {" "}
                  <FormattedMessage
                    id="project_overview.status"
                    defaultMessage="Status"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Select
                    name="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    className="project-overview_status-select"
                  >
                    {Object.values(Status).map((val) => {
                      return (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <ErrorMessage
                    name="status"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="sport" className="col-sm-3 form-label">
                  {" "}
                  <FormattedMessage
                    id="project_overview.sport"
                    defaultMessage="Sport"
                  />
                </label>
                <div className="col-sm-9">
                  <Select
                    isMulti
                    defaultValue={cleanSports}
                    onChange={(selectedOptions) =>
                      formik.setFieldValue(
                        "sport",
                        selectedOptions.map((opt) => opt.value)
                      )
                    }
                    options={sportsOptions}
                    placeholder={intl.formatMessage({
                      id: "select_multiple_sports",
                      defaultMessage: "Select sports",
                    })}
                  />

                  <ErrorMessage
                    name="sport"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="prioritisation" className="col-sm-3 form-label">
                  {" "}
                  <FormattedMessage
                    id="project_overview.disciplines"
                    defaultMessage="Disciplines"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Select
                    name="disciplines"
                    value={formik.values.disciplines}
                    onChange={formik.handleChange}
                    className="project-overview_status-select"
                  >
                    {Object.values(Discipline).map((val) => {
                      return (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <ErrorMessage
                    name="disciplines"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="prioritisation" className="col-sm-3 form-label">
                  {" "}
                  <FormattedMessage
                    id="project_overview.prioritisation"
                    defaultMessage="Prioritisation"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Select
                    name="prioritisation"
                    value={formik.values.prioritisation}
                    onChange={formik.handleChange}
                    className="project-overview_status-select"
                  >
                    {Object.values(Prioritisation).map((val) => {
                      return (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <ErrorMessage
                    name="prioritisation"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="projectType" className="col-sm-3 form-label">
                  {" "}
                  <FormattedMessage
                    id="project_overview.projectType"
                    defaultMessage="Project Type"
                  />
                </label>
                <div className="col-sm-9">
                  <Form.Select
                    name="projectType"
                    value={formik.values.projectType}
                    onChange={formik.handleChange}
                  >
                    {Object.values(ProjectType).map((val) => {
                      const isDisabled =
                        val.includes("WIPS") && !(WIPSUser || practitioner);
                      return (
                        <option key={val} value={val} disabled={isDisabled}>
                          {val}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex">
              <button
                type="button"
                className="btn btn-secondary-outline"
                onClick={handleCancel}
              >
                <FormattedMessage id="project.cancel" defaultMessage="Cancel" />
              </button>
              <button
                type="submit"
                className="btn btn-secondary ms-auto"
                disabled={formik.isSubmitting}
              >
                {!formik.isSubmitting ? (
                  <FormattedMessage
                    id="form.save_changes"
                    defaultMessage="Save Changes"
                  />
                ) : (
                  <FormattedMessage
                    id="form.saving_changes"
                    defaultMessage="Saving Changes"
                  />
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ProjectKeyInfoForm;
