import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import { components } from "../../types/projects";
import arrayAsCommaList from "../../utils/arrayAsCommaList";

interface Props {
  project: components["schemas"]["Project"];
  onClickEdit: (value: boolean) => void;
}

const ProjectKeyInfoReadonly = (props: Props) => {
  const { project, onClickEdit } = props;

  const handleEdit = () => {
    onClickEdit(true);
  };

  return (
    <div className="card">
      <div className="card-header d-flex gap-2">
        <div className="d-flex flex-column justify-content-center">
          <FormattedMessage
            id="project_overview.keyInfo"
            defaultMessage="Project Key Info"
          />
        </div>
        <button
          type="button"
          className="btn btn-outline-secondary ms-auto"
          onClick={handleEdit}
        >
          {" "}
          <FormattedMessage id="form.edit" defaultMessage="Edit" />
        </button>
      </div>
      <div className="card-body">
        <table className="table">
          <tbody>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.status"
                  defaultMessage="Status"
                />
              </th>
              <td>{project.status}</td>
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.sport"
                  defaultMessage="Sport"
                />
              </th>
              <td>{arrayAsCommaList(project.sport)}</td>
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.disciplines"
                  defaultMessage="Disciplines"
                />
              </th>
              <td>{project.disciplines}</td>
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.prioritisation"
                  defaultMessage="Prioritisation"
                />
              </th>
              <td>{project.prioritisation}</td>
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.ranking"
                  defaultMessage="Ranking"
                />
              </th>
              <td></td>
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.type"
                  defaultMessage="Type"
                />
              </th>
              <td>
                {project.projectType ? project.projectType : "Sport Wales"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectKeyInfoReadonly;
