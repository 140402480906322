import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import { components } from "../../types/projects";

interface Props {
  project: components["schemas"]["Project"];
  onClickEdit: (value: boolean) => void;
}

const ProjectDescriptionReadonly = (props: Props) => {
  const { project, onClickEdit } = props;

  const handleEdit = () => {
    onClickEdit(true);
  };

  return (
    <div className="card">
      <div className="card-header d-flex gap-2">
        <div className="d-flex flex-column justify-content-center">
          <FormattedMessage
            id="project_overview.description"
            defaultMessage="Project Description"
          />
        </div>
        <button
          type="button"
          className="btn btn-outline-secondary ms-auto"
          onClick={handleEdit}
        >
          {" "}
          <FormattedMessage id="form.edit" defaultMessage="Edit" />
        </button>
      </div>
      <div className="card-body">
        <table className="table">
          <tbody>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.title"
                  defaultMessage="Title"
                />
              </th>
              <td>{project.title}</td>
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.performance_question"
                  defaultMessage="Performance Question"
                />
              </th>
              <td className="ws-pre-line">{project.performanceChallenge}</td>
            </tr>

            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.insight"
                  defaultMessage="Insight"
                />
              </th>
              <td className="ws-pre-line">{project.insight}</td>
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.desired_outcome"
                  defaultMessage="Desired Outcome"
                />
              </th>
              <td className="ws-pre-line">{project.desiredOutcome}</td>
            </tr>
            <tr>
              <th scope="row">
                {" "}
                <FormattedMessage
                  id="project_overview.sustainable_contribution"
                  defaultMessage="Sustainable Contribution"
                />
              </th>
              <td className="ws-pre-line">{project.sustainableContribution}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectDescriptionReadonly;
